<template>
  <div>
    <vx-card title="Vorgangsmanager" >

      <div class="mb-4 flex justify-end">
        <router-link :to="{ name: 'process-create' }">
          <vs-button color="success" type="filled" size="medium" icon="add">
            Neuen Prozess erstellen
          </vs-button>
        </router-link>
      </div>


      <vue-bootstrap4-table
          v-if="dataFetched"
          :rows="data"
          :columns="columns"
          :config="config"
          :actions="actions"
          @on-change-query="onChangeQuery"
          :total-rows="totalRows"
          @on-select-row="onSelectRows"
          @on-unselect-row="onSelectRows"
          @on-all-unselect-rows="onSelectRows"
          @on-all-select-rows="onSelectRows"
          columnSelection
      >
        <template slot="global_search-after">
          <div class="ml-4 mt-2 flex">
            <vs-switch color="success" v-model="showAllProcesses" @change="fetchData()" />
            <p class="ml-2">Erledigte Vorgänge auch anzeigen</p>
          </div>
        </template>

        <template slot="status" slot-scope="props">
          <div class="vbt-table-td">
            <div class="pl-2">
              <vx-tooltip :text="props.row.status">
                                <span class="status-badge"
                                      :style="{ backgroundColor: getStatusColor(props.row.status) }">{{props.row.status}}</span>
              </vx-tooltip>
            </div>
          </div>
        </template>

        <template slot="title" slot-scope="props">
          <div class="vbt-table-td text-left pl-3">
            {{ props.row.title }}
          </div>
        </template>

        <template slot="assigned_user" slot-scope="props">
          <div class="vbt-table-td">
            <vs-row v-if="props.row.assigned_user">
              {{ props.row.assigned_user.detail.first_name }} {{ props.row.assigned_user.detail.last_name }}
            </vs-row>
            <vs-row v-else>
              <span class="text-danger">Kein Benutzer zugewiesen</span>
            </vs-row>
          </div>
        </template>

        <template slot="created_at" slot-scope="props">
          <div class="vbt-table-td">
            {{ formatDate(props.row.created_at) }}
          </div>
        </template>

        <template slot="row_actions" slot-scope="props">
          <div class="column-actions-group">
            <!-- Bearbeiten-Button -->
            <router-link :to="{ name: 'process-edit', params: { id: props.row.id } }">
              <vs-button color="primary" type="filled" size="small" icon="edit">
                Bearbeiten
              </vs-button>
            </router-link>

            <!-- Anzeigen-Button -->
            <router-link :to="{ name: 'process-view', params: { id: props.row.id } }">
              <vs-button color="success" type="border" size="small">
                Anzeigen
              </vs-button>
            </router-link>
          </div>
        </template>


        <template slot="empty-results">
          Keine Vorgänge gefunden.
        </template>
      </vue-bootstrap4-table>

      <div v-if="selectedProcesses.length >= 1" class="flex items-center mb-4">
        <!-- Status Dropdown -->
        <vs-select v-model="bulkStatus" class="mr-4" placeholder="Status wählen">
          <vs-select-item v-for="status in statusOptions" :key="status.value" :value="status.value" :text="status.label"></vs-select-item>
        </vs-select>

        <!-- Button: Status ändern -->
        <vs-button color="primary" class="ml-2" @click="bulkUpdateStatus">Status ändern</vs-button>

        <!-- Button: Vorgänge löschen -->
        <vs-button color="danger" class="ml-4" @click="bulkDeleteProcesses">Ausgewählte löschen</vs-button>
      </div>

    </vx-card>
  </div>
</template>

<script>
import VueBootstrap4Table from "@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table";
import qs from "qs";
import ApiService from "../../../api";
import QueryHelper from "@/mixins/helper/query.helper";
import moment from "moment";

export default {
  components: {
    VueBootstrap4Table,
  },
  data() {
    return {
      dataFetched: false,
      showAllProcesses: false,
      data: [],
      actions: [],
      selectedProcesses: [],
      bulkStatus: null,
      statusOptions: [
        { value: "New", label: "Neu" },
        { value: "In Progress", label: "In Bearbeitung" },
        { value: "On Hold", label: "Zurückgestellt" },
        { value: "Rejected", label: "Abgelehnt" },
        { value: "Completed", label: "Erledigt" },
      ],
      columns: [
        {
          label: "ID",
          name: "id",
          showCol: false,
          hide: true,
          uniqueId: true,
        },
        {
          label: "Titel",
          name: "title",
          slot_name: "title",
          filter: {
            type: "simple",
            placeholder: "Titel eingeben...",
          },
          sort: true,
          showCol: true,
        },
        {
          label: "Status",
          name: "status",
          slot_name: "status",
          filter: {
            type: "select",
            options: [
              { value: "New", label: "Neu" },
              { value: "In Progress", label: "In Bearbeitung" },
              { value: "On Hold", label: "Zurückgestellt" },
              { value: "Rejected", label: "Abgelehnt" },
              { value: "Completed", label: "Erledigt" },
            ],
          },
          sort: true,
          showCol: true,
        },
        {
          label: "Zugewiesen an",
          name: "assigned_user",
          slot_name: "assigned_user",
          sort: false,
          showCol: true,
        },
        {
          label: "Erstellt am",
          name: "created_at",
          slot_name: "created_at",
          sort: true,
          showCol: true,
        },
        {
          label: "Aktionen",
          slot_name: "row_actions",
          sort: false,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          column_classes: "actions-column",
          showCol: true,
        },
      ],
      config: {
        checkbox_rows: true,
        rows_selectable: false,
        page: 1,
        per_page: 10,
        server_mode: true,
      },
      queryParams: {
        filters: [],
        sort: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
      totalRows: 0,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    requestParams() {
      const params = QueryHelper.parseRequestParams(this.queryParams);

      if (!this.showAllProcesses) {
        params.filter.push({
          field: "status",
          expression: "in",
          value: ["New", "In Progress", "On Hold"], // Nur diese Status anzeigen
        });
      }

      params.order = {
        field: "created_at",
        direction: "desc",
      };

      return params;
    },
  },
  methods: {
    fetchData() {
      ApiService.get("process", {
        params: this.requestParams,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "indices" }),
      })
          .then((response) => {
            if (response.data.status === "success") {
              this.data = response.data.result;
              this.totalRows = response.data.total;
              this.dataFetched = true;
            } else {
              this.showError("Es ist ein Fehler aufgetreten.1");
            }
          })
          .catch((error) => {
            console.log(error);
            this.showError("Es ist ein Fehler aufgetreten.2");
          });
    },
    onChangeQuery(queryParams) {
      if (!this.dataFetched) return;
      this.queryParams = queryParams;
      this.fetchData();
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getStatusColor(status) {
      switch (status) {
        case "New":
          return "#007bff";
        case "In Progress":
          return "#ffc107";
        case "On Hold":
          return "#17a2b8";
        case "Rejected":
          return "#dc3545";
        case "Completed":
          return "#28a745";
        default:
          return "#6c757d";
      }
    },
    showError(message) {
      this.$vs.notify({
        title: "Fehler",
        text: message,
        color: "danger",
        iconPack: "feather",
        icon: "icon-alert-circle",
      });
    },
    onSelectRows(payload) {
      this.selectedProcesses = payload.selected_items.map((item) => item.id);
    },
    bulkUpdateStatus() {
      if (this.selectedProcesses.length === 0) {
        return this.$vs.notify({
          title: "Fehlgeschlagen",
          text: "Bitte wähle mindestens einen Prozess aus.",
          color: "danger",
        });
      }

      if (!this.bulkStatus) {
        return this.$vs.notify({
          title: "Fehlgeschlagen",
          text: "Bitte wähle einen neuen Status aus.",
          color: "warning",
        });
      }

      this.$vs.loading();
      ApiService.put("process/bulk-update-status", {
        process_ids: this.selectedProcesses,
        status: this.bulkStatus,
      })
          .then((response) => {
            this.$vs.loading.close();
            if (response.data.status === "success") {
              this.$vs.notify({
                title: "Erfolgreich",
                text: "Status erfolgreich aktualisiert.",
                color: "success",
              });
              this.fetchData();
            } else {
              this.$vs.notify({
                title: "Fehlgeschlagen",
                text: "Status konnte nicht aktualisiert werden.",
                color: "danger",
              });
            }
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Fehlgeschlagen",
              text: "Ein Fehler ist aufgetreten.",
              color: "danger",
            });
          });
    },

    bulkDeleteProcesses() {
      if (this.selectedProcesses.length === 0) return;

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Vorgänge löschen",
        text: "Möchtest du die ausgewählten Vorgänge wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
        accept: () => {
          this.$vs.loading();
          ApiService.put("process/bulk-delete", {
            process_ids: this.selectedProcesses,
          })
              .then((response) => {
                this.$vs.loading.close();
                if (response.data.status === "success") {
                  this.$vs.notify({
                    title: "Erfolgreich",
                    text: "Vorgänge erfolgreich gelöscht.",
                    color: "success",
                  });
                  this.fetchData();
                } else {
                  this.$vs.notify({
                    title: "Fehlgeschlagen",
                    text: "Vorgänge konnten nicht gelöscht werden.",
                    color: "danger",
                  });
                }
              })
              .catch(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Fehlgeschlagen",
                  text: "Ein Fehler ist aufgetreten.",
                  color: "danger",
                });
              });
        },
      });
    }

  },
};
</script>

<style scoped>
.actions-column {
  width: 100px;
}

span.status-badge {
  padding: 5px 13px;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
}
</style>
